body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.stake-file-uploader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 1px solid #ced4da;
  color: #ced4da;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stake-file-uploader input {
  opacity: 0%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  cursor: pointer;
}



.stake-file-uploader-box {
  /* width: 100%; */
  /* height: 100%; */
  /* position: absolute; */
  font-size: 30px;
  z-index: 8;
}

.stake-file-uploader-box-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}

.stake-file-uploader-box-img {
  max-width: 100%;
  height: auto;

}

.search-modal {
  background-color: #141721 !important;
  border: 1px solid #666666;
}

.search-modal-search-input {
  width: 100%;
  color: #ffffff;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.search-modal-search-box {
  margin-top: 10px;
  font-size: 12px;
}

.search-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.search-history-item {
  font-size: 12px;
  /* color: rgba(255, 255, 255, 0.8); */
  color: #a1a0a7;
  /* border: 1px solid rgba(255, 255, 255, 0.8); */
  border: 1px solid #a1a0a7;
  border-radius: 7px;
  padding: 1px 7px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.search-modal-search-box-list {
  width: 100%;
  margin-top: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.search-modal-search-box-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 15px; */
  padding-top: 7px;
  padding-bottom: 7px;
}

.search-modal-search-box-list-item:hover {
  background-color: #1b1e2b;
  cursor: pointer;
}

.search-history-item:hover {
  background-color: #1b1e2b;
}


.search-modal-search-box-list-item-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.search-modal-search-box-list-item-price {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-modal-search-box-list-item-increase {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-modal-search-box-list-item-title {
  margin-left: 10px;
}

.cropper-body {
  position: relative;
  height: 210px;
  margin-top: 10px;
}